import "./App.css";
// import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Programmes from "./components/Programmes/Programmes";
import Reasons from "./components/Reasons/Reasons";
import Plans from "./components/Plans/Plans";
import Testimonials from "./components/Testimonials/Testimonials";
import Join from "./components/Join/Join";
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Hero />
      <Programmes />
      <Reasons />
      <Plans />
      <Testimonials/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;
