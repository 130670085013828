import React from "react";
import "./Programmes.css";
import RightArrow from "../assets/rightArrow.png"
import { programsData } from "../../data/programsData";
const Programmes = () => {
  return (
    <div className="Programmes" id="programmes">
      {/* Header */}
      <div className="programmes-header">
        <span className="stroke-text">Explore our </span>
        <span>programmes </span>
        <span className="-text">to shape you</span>
      </div>

      <div className="programmes-categories">
        {programsData.map((program) => (
          <div className="category">
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now">
              <span>Join Now</span><img src={RightArrow} alt=""/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programmes;